<template>
  <div class="map-intro-card" v-if="!showId && territory && intro">
    <div class="eq-title">{{ territory }}</div>
    <div class="intro" v-html="intro"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getFarmInfoApi } from "@/request/api";

export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      intro: "",
      territory: "",
    };
  },
  created() {
    this.getFarmInfoApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  methods: {
    async getFarmInfoApi() {
      const { code, results } = await getFarmInfoApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.territory = results.territory;
      this.intro = results.intro;
      this.intro = this.intro.replace(/<\s*img/g, '<img style="width:100%;"');
      this.intro = this.intro.replace(
        /<\s*p/g,
        '<p style=" width: 100%;display: flex;"'
      );
    },
  },
};
</script>

<style lang="less" scoped>
.map-intro-card {
  height: 408px;
  width: 300px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #0f243d;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  .eq-title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    background-color: rgba(21, 92, 165, 0.9);
    padding: 0 10px;
  }
  .intro {
    width: 100%;
    height: calc(100% - 30px);
    padding: 10px;
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    overflow: auto;
    text-indent: 2em;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }
  }
}
</style>
